import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider';
import CallSharp from '@material-ui/icons/CallSharp';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';

export default function SideBar(){
    return (
        <Grid container >
            <Grid item xs={12}>
                <Paper elevation={1} style={{ padding :  15 }} >
                    <Typography variant='button'  style={{ fontSize : 15 }} >  Support </Typography>
                    <Divider />
                    <Grid container spacing={15} style={{ paddingTop : 20 }}>
                        <Grid item xs={12} style={{  display :'flex', alignItems : 'center', paddingTop : 10 , paddingBottom : 10 }} >
                            <CallSharp style={{ marginRight : 15 ,  fontSize : 20 }} />  <Typography > 85913 36124 </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ display :'flex', alignItems : 'center', paddingTop : 10 , paddingBottom : 10 }} >
                            <a href='https://wa.me/918591336124' target='_blank' rel="noreferrer" style={{ color : '#333', textDecoration :'none' , display : 'flex' }}  >
                                <WhatsAppIcon style={{ marginRight : 15, color : '#128C7E' , fontSize : 20 }}  />  <Typography >  85913 36124 </Typography>
                            </a>
                        </Grid>
                        <Grid item xs={12} style={{ display :'flex', lignItems : 'center', paddingTop : 10 , paddingBottom : 10 }} >
                            <Typography variant='caption' > Our Working hours are Monday - Saturday ( 10 am - 7 pm )</Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>

            <Grid item xs={12} style={{ paddingTop : 30 }} >
                <Paper elevation={1} style={{ padding :  15 }} >
                    <Typography variant='button'  style={{ fontSize : 15 }} >  Follow Us </Typography>
                    <Divider />
                    <Grid container spacing={15} style={{ paddingTop : 20 }}>
                        <Grid item xs={12} style={{  display :'flex', alignItems : 'center', paddingTop : 10 , paddingBottom : 10 }} >
                            <a href='https://www.instagram.com/patilkaki/' target='_blank'  rel="noreferrer" style={{ color : '#333', textDecoration :'none' , display : 'flex' }}  >
                                <InstagramIcon style={{ marginRight : 15 ,  fontSize : 20 }} />  <Typography >  @patilkaki </Typography>
                            </a>
                        </Grid>
                        <Grid item xs={12} style={{ display :'flex', alignItems : 'center', paddingTop : 10 , paddingBottom : 10 }} >
                            <a href='https://www.facebook.com/patilkakikitchen/' target='_blank' rel="noreferrer" style={{ color : '#333', textDecoration :'none' , display : 'flex' }}  >
                                <FacebookIcon style={{ marginRight : 15 , fontSize : 20 }}  />  <Typography >  @patilkakikitchen </Typography>
                            </a>
                        </Grid>
                        <Grid item xs={12} style={{ display :'flex', lignItems : 'center', paddingTop : 10 , paddingBottom : 10 }} >
                            <Typography variant='caption' > Follow us to stays tuned with new launches, discounts and a lot more!</Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )
}