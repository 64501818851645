import Grid from '@material-ui/core/Grid'

export default function Header(){

    return(
        <Grid container justify="center" style={{ background: '#fff' ,  paddingTop : 10 , paddingBottom : 10 , borderBottom : '1px solid #eee'}}>
            <Grid item xs={4} style={{ display : 'flex' , justifyContent : 'center' }}>
                <a href='https://patilkaki.com' target='_blank'  rel="noreferrer" >
                    <img src="https://patilkaki.com/wp-content/uploads/2020/10/pk-logo-min.png"  style={{ maxWidth : 150 }} ></img>
                </a>
            </Grid>
        </Grid>
    )
}