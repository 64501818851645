import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Grid from '@material-ui/core/Grid';
import Header from './layouts/header';
import SideBar from './layouts/sidebar';
import Footer from './layouts/footer';
import ShowDetails from './components/showDetails';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";


ReactDOM.render(
  <Router>
  <React.StrictMode>
      <Grid container>
          <Grid item xs={12}>
                <Header />
          </Grid>
          <Grid item xs={12}>
              <Grid container spacing={15} style={{ marginLeft : 'auto', marginRight : 'auto', width : '95%', maxWidth : 1000, paddingTop : 30 , paddingBottom : 30  }}>
                  <Grid item xs={12} md={8} style={{ padding : 20 }} >
                      <Switch>
                        <Route path="/:orderIdParam">
                          <ShowDetails />
                        </Route>
                        <Route path="/">
                          <ShowDetails />
                        </Route>
                        
                      </Switch>
                  </Grid>
                  <Grid item xs={12} md={4} style={{ padding : 20 }}>
                      <SideBar />
                  </Grid>
              </Grid>
          </Grid>
          <Grid item xs={12} style={{ backgroundColor : '#fff'}} justify="center" >
            <Grid container spacing={15} style={{ marginLeft : '10%', maxWidth : '80%', paddingTop : 30 , paddingBottom : 30  }}>
                <Grid item xs={12}>
                    <Footer />  
                </Grid>
              
            </Grid>
          </Grid>
      </Grid>    
  </React.StrictMode>
  </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
