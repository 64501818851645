import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

export default function Footer(){

    return(
        <Grid container justify="center" style={{   background: '#fff' ,  paddingTop : 10 , paddingBottom : 10 , borderBottom : '1px solid #eee'}}>
            <Grid item xs={12} md={4} style={{ display : 'flex' , justifyContent : 'flex-start' , alignItems :'flex-start' }}>
                <img src="https://patilkaki.com/wp-content/uploads/2020/10/pk-logo-min.png"  style={{ maxWidth : 150 }} ></img>
            </Grid>
            <Grid item xs={12} md={4} style={{ paddingTop : 10 }}  >
                
                <Typography variant='subtitle2' style={{ paddingTop : 3 }}  > 
                    <a href='https://patilkaki.com/about' style={{ textDecoration : 'none' , color : '#6a6a6a'}} target='_blank' rel="noreferrer" > 
                        About Us 
                    </a> 
                </Typography>

                <Typography variant='subtitle2' style={{ paddingTop : 3 }}  > 
                    <a href='https://patilkaki.com/privacy-policy/' style={{ textDecoration : 'none' , color : '#6a6a6a'}} target='_blank' rel="noreferrer" > 
                        Privacy Policy
                    </a> 
                </Typography>

                <Typography variant='subtitle2' style={{ paddingTop : 3 }}  > 
                    <a href='https://patilkaki.com/refer/' style={{ textDecoration : 'none' , color : '#6a6a6a'}} target='_blank' rel="noreferrer" > 
                        Refer & Earn
                    </a> 
                </Typography>
                
            </Grid>
            <Grid item xs={12} md={4} style={{ paddingTop : 10 }} >
                <Typography variant='subtitle2' > "We at PatilKaki strive to improve your expirence as a customer, Only High Quality oil & other raw materials are used for preparing these tasty homemade snacks for you!"</Typography> <br />
                <Typography variant='subtitle2' > - Team PatilKaki</Typography>
            </Grid>
        </Grid>
    )
}