import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { useState , useEffect } from 'react';
import axios from 'axios';
import LinearProgress from '@material-ui/core/LinearProgress';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIosSharp from '@material-ui/icons/ArrowForwardIosSharp';
import ArrowBack from '@material-ui/icons/ArrowBackIosSharp';
import {
    BrowserRouter as Router,
    useRouteMatch,
    Link
  } from "react-router-dom";

export default function ShowDetails( ){
    let match = useRouteMatch();
    let { params } = match ; 
    let { orderIdParam } = params ;
    
    const [loading, setLoading ] = useState(false);
    const [orderId, setOrderId ] = useState();
    const [order, setOrder ] = useState();
    const [step, setStep ] = useState(1);
    const fetchOrderDetails = ( orderId ) => {
        axios.get(`https://pok-backend-y3hbx.ondigitalocean.app/api/v2/order/track/${orderId}`).then(order => {
            setLoading(true);
            setOrder(order.data);
            setStep(2);
            setLoading(false);
            
        }); 
    }
    useEffect(() => {
        
        if(orderIdParam){
            fetchOrderDetails(orderIdParam);
            setOrderId(orderIdParam);
        }

    },[orderId]);
    return(
        <Paper elevation={1} style={{ minHeight : 500 }}>
            {loading && <LinearProgress />}
            {!loading && step === 1  && 
                <Grid container justify="center" style={{ padding : 20  }} >
                    
                    <Grid item xs={12} style={{ display : 'flex' , flexDirection : 'column', alignItems : 'center' , paddingTop : 20 }} >
                        <Typography variant='overline'  style={{ display : 'block'}}  > Enter Your Order Id  </Typography>
                        
                        <div style={{ border : '1px solid #eee' ,  display : 'inline-block'}} >
                            <InputBase
                                placeholder="123456"
                                style={{ paddingLeft : 15 , fontSize : 12 }}
                                inputProps={{ 'aria-label': 'search google maps' }}
                                value={orderId}
                                onChange={(e)=> setOrderId(e.target.value)}
                            />
                            <IconButton 
                                onClick={() => fetchOrderDetails(orderId)}
                                style={{  backgroundColor : '#ff6a32' , borderRadius : 0  }}>
                                <ArrowForwardIosSharp style={{ fontSize : 10 , color : '#fff'}} />
                            </IconButton>    
                        </div>
                        <Typography variant='caption' style={{ paddingTop : 15 , fontSize : 10 , maxWidth : 250 , textAlign : 'center'}} > ( Please Note - Orders Only Within Mumbai, Thane & Navi Mumbai can be tracked here ) </Typography>
                    </Grid>
                </Grid>
            }
            {!loading && step === 2 && <Grid item xs={12} style={{ display : 'flex' , justifyContent : 'center' , paddingTop : 20 }} >
                    <Link to="/" onClick={() => setStep(1)}  style={{ color : '#333' , textDecoration : 'none'}} >
                        <Typography variant='caption' style={{ display : 'flex' , alignItems : 'center' , cursor : 'pointer', textTransform : 'uppercase'}} > 
                            <ArrowBack  style={{ fontSize : 12 }} /> Track Another Order 
                        </Typography>
                    </Link>
            </Grid>}
            {!loading && step ===  2 && order && 
            <Grid container justify="center" style={{ padding : 20 }}>
                <Grid item xs={12} style={{ paddingBottom : 30 }}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography variant='caption' >Est Delivery Date</Typography>
                            <Typography variant='h5' >{order.est_delivery_date_formatted_2}</Typography>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign : 'right'}} >
                            <Typography variant='caption' >Order ID</Typography>
                            <Typography variant='h5' >#{orderId}</Typography>
                        </Grid>
                    </Grid>
                    
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <LinearProgress variant="determinate" value={order.currentProgress} />
                            <Typography variant='subtitle2' style={{ textTransform : 'capitalize', paddingTop : 15 }} > Current Status : {order.status}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ paddingTop : 30 , paddingBottom : 30 }} >
                    <Typography variant='caption' style={{ textTransform : 'capitalize', paddingTop : 15 }} >{order.message}</Typography>
                </Grid>
                <Grid item xs={12} style={{ paddingTop : 30 }} >
                    <Grid container style={{ paddingBottom : 20 , paddingTop : 20 }} >
                        <Grid item xs={12}>
                            <Typography variant='caption' style={{ textTransform : 'uppercase'}} > Order Items </Typography>
                        </Grid>
                    </Grid>
                    <Grid container >
                        {order.order_items.map(item => {
                            return(
                                <Grid item xs={12} key={item.variation_id}>
                                    <Grid container >
                                        <Grid item xs={6} style={{ borderTop : '1px solid #ccc'}} >
                                            <Typography variant='body2'  style={{ paddingTop : 5 , paddingBottom : 5 }} >{item.name}</Typography>
                                        </Grid>
                                        <Grid item xs={6} style={{ textAlign : 'right'}} >
                                            <Typography variant='body2' >Qty : {item.qty}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
            </Grid>}

            {!loading && step === 2 && !order && <Grid item xs={12} style={{ display : 'flex' , justifyContent : 'center' , paddingTop : 50 , paddingBottom : 50 }} >
                    <Typography variant='caption' style={{ display : 'flex' , alignItems : 'center' , cursor : 'pointer', textTransform : 'uppercase'}} > 
                        Invalid Order Id Entered !
                    </Typography>
            </Grid>}

            
        </Paper>
    )
}